import React, { useEffect, useState, useContext } from 'react';
import { NavLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import '../../styles/Applicationcreates.css';
import { Grid, Select, MenuItem, Button, Typography } from "@material-ui/core";
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import '../../styles/Modal.css';
import Modalpopup from '../commonViews/Modal';
import ModalpopupOffer from '../commonViews/ModalOffer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import baseUrl from '../../config/config';
import { useFormik } from 'formik';
import '../../styles/Bgimages.css';
import '../../styles/style.css'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { FormContext, ReviewMatrixContext, ApplicationStatusContext } from '../../../context/FormContext';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Visibility from "@material-ui/icons/Visibility";
import Reviewmodal from './Reviewmodal';
import CircularProgress from '@material-ui/core/CircularProgress';
import CommonHeader from './CommonHeader';
import DocusignEnvelopeModal from '../commonViews/DocusignEnvelopeModal';
import NumberFormat from 'react-number-format';
import "../../styles/styleNew.css";

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 0;
var ITEM_MARGIN_TOP = 10;
if (window.innerWidth <= 320) {
    ITEM_MARGIN_TOP = 210;
} else if (window.innerWidth <= 375) {
    ITEM_MARGIN_TOP = 200;
} else if (window.innerWidth <= 425) {
    ITEM_MARGIN_TOP = 195;
} else if (window.innerWidth <= 768) {
    ITEM_MARGIN_TOP = 115;
} else {
    ITEM_MARGIN_TOP = 0;
}
const MenuProps = {
    color: '#000 !important',
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
            marginTop: ITEM_MARGIN_TOP
        },
    },
};

const StyledMenuItem = withStyles((theme) => ({
    root: {
        fontSize: "13px !important",
        minHeight: "1.2em",
        padding: "0px 0px 0px 10px",
        color: '#000 !important',
        '&:hover': {
            backgroundColor: '#067de2 !important',
            color: '#fff !important',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: '#OOO !important',
                fontSize: '16px !important',
            },
        },
    },
    selected: {
        backgroundColor: '#ffff !important',
        color: '#000 !important',
    }
}))(MenuItem);


const useStyles = makeStyles({
    root: {
        padding: '0px !important'
    },
    tableContainer: {
        padding: '0px 160px 0px 160px',
        position: 'relative',
    },
    table:
    {
        height: '300px',
        border: '2px dashed #E0E0E0',
        borderRadius: '15px'
    },
    tableview:
    {
        border: '2px solid #E0E0E0',
        borderRadius: '15px'
    },
    primary_btn:
    {
        margin: '0px 10px !important',
        borderRadius: '25px !important',
        padding: '2px 15px !important',
        fontSize: '12px !important',
        background: '067de2 !important',
        height: '35px'
    },
    secondary_btn:
    {
        margin: '0px 10px !important',
        borderRadius: '25px !important',
        padding: '2px 15px !important',
        fontSize: '12px !important',
        height: '35px'
    },
    tableRow: {
        borderBottom: '2px dashed #E0E0E0',
        backgroundColor: '#F8F8F8',
    },
    tablecell1:
    {
        textAlign: 'left',
        marginRight: '10px',
        fontFamily: 'Poppins-Regular',
        fontWeight: '600 !important',
        textTransform: 'none',
        color: '#666666',
        padding: '10px 30px',
        paddingLeft: 120,
    },
    tablecell2:
    {
        textAlign: 'left',
        marginRight: '10px',
        fontFamily: 'Poppins-Regular',
        fontWeight: '600 !important',
        textTransform: 'capitalize !important',
        color: '#666666',
        padding: '10px 30px',
        paddingRight: 120,
    },
    tablecell3:
    {
        textAlign: 'left',
        marginRight: '10px',
        fontFamily: 'Poppins-Regular',
        fontWeight: 'bolder !important',
        textTransform: 'capitalize !important',
        color: '#666666',
        padding: '10px 30px',
        // paddingLeft: 120,
    },
    btn_head: {
        paddingRight: '0px !important'
    },
});

const data = [
    { "name": "Ross Geller", "offer_price": "$765,000", "Term_Days": "90 Days", "Address": "CasaParadiso", "gmail": "Oliva@gmail.com", "loan_balance": "$344,500", "home_value": "$840,000", "TakeoutOffer": "$676,000", "subAddr": "32, street Himachal Pradesh" }
]

export default function OfferDetails(props) {
    const [loader, setLoader] = useState(false)
    const [calculatedValues,setCalculatedValues] = useContext(ReviewMatrixContext);
    const [loaderSigning, setLoaderSigning] = useState(false)
    const [forms, setForms] = useContext(FormContext);
    const [historyDatasv, setHistoryDatasv] = useState([])
    const [docusignEnvelopeStatus, setDocusignEnvelopeStatus] = useState(false);
    const [openSnackbarDocusignEnvelope, setOpenSnackbarDocusignEnvelope] = useState(false);
    const [openSnackbarSendSigningUrl, setOpenSnackbarSendSigningUrl] = useState(false);
    const [openSnackbarSendOffer, setOpenSnackbarSendOffer] = useState(false);
    const [openSnackbarValidOffer, setOpenSnackbarValidOffer] = useState(false);
    const [openSnackbarSubmitStage, setOpenSnackbarSubmitStage] = React.useState(false);
    const [templateId, setTemplateId] = useState(null)
    const [templateDataRes, setTemplateDataRes] = useState(null);
    const [offer, setOffer] = React.useState(false);
    const [docusignEnvelope, setDocusignEnvelope] = React.useState(false);
    const [signUrl, setSignUrl] = React.useState(false);
    const [offerHistory, setOfferHistory] = React.useState(false);
    const [openSubmit, setOpenSubmit] = React.useState(false);
    const [openOfferSelectTitle, setOpenOfferSelectTitle] = useState("");
    const [validOffer, setValidOffer] = React.useState(false);
    //const [nuthshellAPILoader, setNutshellAPILoader] = useState(false)
    const [submittedCRM, setSubmittedCRM] = useState(false);
    const [detail, setDetail] = React.useState('');
    const [severity_type, setSeverityType] = React.useState('');
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [newStatus,setNewStatus] = useContext(ApplicationStatusContext);
    const [offerExpiryDate, setOfferExpiryDate] = useState();
    const [isExpiredDate, setIsExpiredDate] = useState(false);
    const [extendedValidOfferResult, setExtendedValidOfferResult] = useState(null)
    const [isExtendBtn, setIsExtendBtn] = useState(false);
    const [isEnvelopeBtn, setIsEnvelopeBtn] = useState(false);
    const [extendedDays, setExtendedDays] = useState('');
    const [newExpiryOrExpiredDate, setNewExpiryOrExpiredDate] = useState('');
    const [extendDaysErrMsg, setExtendDaysErrMsg] = useState('');
    const [state, setState] = React.useState({
        checkedB: true,
    });
    let home_1_value = !props.isCalculated ? forms?.calculated_offer?.home_1_value === null ? '' : "$" + "" + forms?.calculated_offer?.home_1_value?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.home_1_value === null ? '' : "$" + "" + calculatedValues?.offer_details.home_1_value?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let home_1_purchase_price = !props.isCalculated ? forms?.calculated_offer?.home_1_purchase_price === null ? '' : "$" + "" + forms?.calculated_offer?.home_1_purchase_price?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.home_1_purchase_price === null ? '' : "$" + "" + calculatedValues?.offer_details.home_1_purchase_price?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let monthly_piti = !props.isCalculated ? forms?.calculated_offer?.monthly_piti === null ? '' : "$" + "" + forms?.calculated_offer?.monthly_piti?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.monthly_piti === null ? '' : "$" + "" + calculatedValues?.offer_details.monthly_piti?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let home_1_mortgage_balance = !props.isCalculated ? forms?.calculated_offer?.home_1_mortgage_balance === null ? '' : "$" + "" + forms?.calculated_offer?.home_1_mortgage_balance?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.home_1_mortgage_balance === null ? '' : "$" + "" + calculatedValues?.offer_details.home_1_mortgage_balance?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let max_available_home_equity_80_percent_cltv = !props.isCalculated ? forms?.calculated_offer?.max_available_home_equity_80_percent_cltv === null ? '' : "$" + "" + forms?.calculated_offer?.max_available_home_equity_80_percent_cltv?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.max_available_home_equity_80_percent_cltv === null ? '' : "$" + "" + calculatedValues?.offer_details.max_available_home_equity_80_percent_cltv?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let calqulator_estimated_equity = !props.isCalculated ? forms?.calculated_offer?.calqulator_estimated_equity === null ? '' : "$" + "" + forms?.calculated_offer?.calqulator_estimated_equity?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.calqulator_estimated_equity === null ? '' : "$" + "" + calculatedValues?.offer_details.calqulator_estimated_equity?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let new_house_maximum_purchase_price = !props.isCalculated ? forms?.calculated_offer?.new_house_maximum_purchase_price === null ? '' : "$" + "" + forms?.calculated_offer?.new_house_maximum_purchase_price?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.new_house_maximum_purchase_price === null ? '' : "$" + "" + calculatedValues?.offer_details.new_house_maximum_purchase_price?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let estimated_downpayment_expense = !props.isCalculated ? forms?.calculated_offer?.estimated_downpayment_expense === null ? '' : "$" + "" + forms?.calculated_offer?.estimated_downpayment_expense?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.estimated_downpayment_expense === null ? '' : "$" + "" + calculatedValues?.offer_details.estimated_downpayment_expense?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let max_he_minus_equity_required = !props.isCalculated ? forms?.calculated_offer?.max_he_minus_equity_required === null ? '' : forms?.calculated_offer?.max_he_minus_equity_required?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.max_he_minus_equity_required === null ? '' : calculatedValues?.offer_details.max_he_minus_equity_required?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let estimated_piti_expenses = !props.isCalculated ? forms?.calculated_offer?.estimated_piti_expenses === null ? '' : "$" + "" + forms?.calculated_offer?.estimated_piti_expenses?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.estimated_piti_expenses === null ? '' : "$" + "" + calculatedValues?.offer_details.estimated_piti_expenses?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let estimated_closing_costs = !props.isCalculated ? forms?.calculated_offer?.estimated_closing_costs === null ? '' : "$" + "" + forms?.calculated_offer?.estimated_closing_costs?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.estimated_closing_costs === null ? '' : "$" + "" + calculatedValues?.offer_details.estimated_closing_costs?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let estimated_commissions = !props.isCalculated ? forms?.calculated_offer?.estimated_commissions === null ? '' : "$" + "" + forms?.calculated_offer?.estimated_commissions?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.estimated_commissions === null ? '' : "$" + "" + calculatedValues?.offer_details.estimated_commissions?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let estimated_calque_fees = !props.isCalculated ? forms?.calculated_offer?.estimated_calque_fees === null ? '' : "$" + "" + forms?.calculated_offer?.estimated_calque_fees?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.estimated_calque_fees === null ? '' : "$" + "" + calculatedValues?.offer_details.estimated_calque_fees?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let equity_required = !props.isCalculated ? forms?.calculated_offer?.equity_required === null ? '' : "$" + "" + forms?.calculated_offer?.equity_required?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.equity_required === null ? '' : "$" + "" + calculatedValues?.offer_details.equity_required?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let homeowner_ppg = !props.isCalculated ? forms?.calculated_offer?.homeowner_ppg === null ? '' : "$" + "" + forms?.calculated_offer?.homeowner_ppg?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.homeowner_ppg === null ? '' : "$" + "" + calculatedValues?.offer_details.homeowner_ppg?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let ppg_percentage = !props.isCalculated ? !forms?.calculated_offer?.ppg_percentage ? '' : forms?.calculated_offer?.ppg_percentage?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.ppg_percentage === null ? '' : calculatedValues?.offer_details.ppg_percentage?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let valid_offer_period = !props.isCalculated ? forms?.calculated_offer?.valid_offer_period === null ? '' : forms?.calculated_offer?.valid_offer_period?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.valid_offer_period === null ? '' : calculatedValues?.offer_details?.valid_offer_period;
    let term_days = !props.isCalculated ? !forms?.calculated_offer?.term_days ? '' : forms?.calculated_offer?.term_days: calculatedValues?.offer_details.term_days === null ? '' : calculatedValues?.offer_details?.term_days;
    let calque_fee = !props.isCalculated ? forms?.calculated_offer?.calque_fee === null ? '' : "$" + "" + forms?.calculated_offer?.calque_fee?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.calque_fee === null ? '' : "$" + "" + calculatedValues?.offer_details.calque_fee?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let administrative_fee = !props.isCalculated ? forms?.calculated_offer?.administrative_fee === null ? '' : "$" + "" + forms?.calculated_offer?.administrative_fee?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.administrative_fee === null ? '' : "$" + "" + calculatedValues?.offer_details.administrative_fee?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let total_calque_fees = !props.isCalculated ? forms?.calculated_offer?.total_calque_fees === null ? '' : "$" + "" + forms?.calculated_offer?.total_calque_fees?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): calculatedValues?.offer_details.total_calque_fees === null ? '' : "$" + "" + calculatedValues?.offer_details.total_calque_fees?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
    const classes = useStyles();
    
    const applicationId = forms?.trade_in_application_id 
    const isTestApp = forms?.is_test_app
    
    const getHistory = (row, forms) => {
        if (forms?.trade_in_application_id) {
            if (row?.offer_summary_id) {
                let tokenStr = localStorage.getItem('access_token')
                fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/get-offer-summary/`,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            "Authorization": `Bearer ${tokenStr}`

                        },
                        body: JSON.stringify({ offer_summary_id: row?.offer_summary_id })
                    })
                    .then(async response => {
                        const results = await response.json()
                        if (response.status === 200) {
                            setHistoryDatasv(results);
                        } else if (response.status === 403) {
                            localStorage.setItem('access_token', '')
                            props.history.push('/');
                        }
                    })
                    .catch((error) => {
                    })
            }
        }
    }
    const [statusoffer, setStatusoffer] = React.useState([])
    const [offerBtn, setOfferBtn] = useState(false);
    const [historyDatas, setHistoryDatas] = useState([])
    const vertical = 'top';
    const horizontal = 'center';

    const options = { weekday: 'long', year: 'numeric', month: 'full', day: 'numeric' };
    options.timeZone = 'UTC';
    options.timeZoneName = 'short';
    const NativeSelect = withStyles({
        root: {
            width: 200
        },
        icon: {
            color: "blue"
        }
    })(Select);

    const status = forms && forms?.application_status?.application_status_id;
    useEffect(()=>{
        if(newStatus === 6 || newStatus === 16 || newStatus === 13 || newStatus === 12) {
            setIsExtendBtn(true);
        } else {
            setIsExtendBtn(false);
        }
        if(newStatus === 6 || newStatus === 16 || newStatus === 12 || newStatus === 13 || newStatus === 18 || newStatus === 19) {
            setIsEnvelopeBtn(true);
        } else {
            setIsEnvelopeBtn(false);
        }
    },[newStatus])
    
    const handleClickOpen1 = () => {
        if (openSnackbarSendOffer === true) {
            setOpenSnackbarSendOffer(false)
        }
        setTimeout(() => {
            if (offer === false) {
                setOffer(true)
            }
        }, 1000)
        setTimeout(() => {
            if (offer === true) {
                setOffer(false)
            }
        }, 100)
    };
    const handleDocusignEnvelope = () => {
        if (openSnackbarDocusignEnvelope === true) {
            setOpenSnackbarDocusignEnvelope(false)
        }
        setDocusignEnvelope(!docusignEnvelope)
    }
    const handleSigningUrl = () => {
        if (openSnackbarSendSigningUrl === true) {
            setOpenSnackbarSendSigningUrl(false)
        }
        setSignUrl(!signUrl)
    }  
    const handleValidOffer = () => {
        if (openSnackbarValidOffer === true) {
            setOpenSnackbarValidOffer(false)
        }
        setValidOffer(!validOffer)
        setExtendedDays('')
        setNewExpiryOrExpiredDate('')
        setExtendDaysErrMsg('')

            let tokenStr = localStorage.getItem('access_token')
            fetch(`${baseUrl.baseUrl}trade-in-application/${forms?.trade_in_application_id}/get-offer-expiry-date/`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${tokenStr}`
                    },
                })
                .then(async response => {
                    const results = await response.json()
                    if (response.status === 200) {
                        setOfferExpiryDate(results.offer_expiry_date)
                    } else if (response.status === 403) {
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
    }
    useEffect(() => {
        const todayDate = new Date().toISOString().slice(0, 10); 
        if(offerExpiryDate < todayDate) {
            setIsExpiredDate(true);
        } else {
            setIsExpiredDate(false);
        }
    }, [offerExpiryDate])
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose1 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    // const handleNutshell = () => {
    //     setNutshellAPILoader(true)
    //     let tokenStr = localStorage.getItem('access_token')
    //     fetch(`${baseUrl.baseUrl}trade-in-application/${forms?.trade_in_application_id}/sync-with-nutshell/`, {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Authorization': `Bearer ${tokenStr}`,
    //             'Content-Type': 'application/json'
    //         },
    //     })
    //         .then(async response => {
    //             const results = await response.json()
    //             if (response.status === 201 || response.status === 200) {
    //                 setDetail(results.message);
    //                 setSeverityType("success");
    //                 setOpenSnackbar(true);
    //                 await sleep(1000);
    //                 setOpenSnackbar(false);
    //                 setNutshellAPILoader(false)
    //                 setSubmittedCRM(true);
    //             } else {
    //                 if (response.status === 403) {
    //                     localStorage.setItem('access_token', '')
    //                     props.history.push('/');
    //                     setNutshellAPILoader(false)
    //                 }
    //                 if (results.detail) {
    //                     setDetail(results.detail)
    //                     setSeverityType("error");
    //                     setOpenSnackbar(true);
    //                     await sleep(1000);
    //                     setOpenSnackbar(false);
    //                     setNutshellAPILoader(false)
    //                 }
    //                 else {
    //                     setDetail("something went wrong")
    //                     setSeverityType("error");
    //                     setOpenSnackbar(true);
    //                     await sleep(1000);
    //                     setOpenSnackbar(false);
    //                     setNutshellAPILoader(false)
    //                 }
    //             }
    //         })
    //         .catch((error) => {
    //             console.log('error', error)
    //         })
    // }
    const handleClickOpen2 = () => {
        if (openSnackbarSubmitStage === true) {
            setOpenSnackbarSubmitStage(false)
        }
        setOpenSubmit(!openSubmit);
    };
    const handleClickOpen3 = () => {
        setTimeout(() => {
            setOfferHistory(!offerHistory);
        }, 500)
    };
    // converting date format from yyyy-mm-dd to mm-dd-yyyy
    let newExpiryStringDate = String(forms?.calculated_offer.new_expiry_date);
    let validOfferStringDate = String(forms?.calculated_offer.valid_offer_extended_date);
    const resultFormattedDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${month}-${day}-${year}`;
    };
    let newExpiryFormattedDate = resultFormattedDate(newExpiryStringDate);
    let validOfferFormattedDate = resultFormattedDate(validOfferStringDate);
    
    const offersData = () => {
        if (forms) {
            return (
                <TableBody style={{ height: '500px' }}>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell3} style={{ lineHeight: '1.35' }}>Existing Home Details</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}></TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Home 1 Value</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{home_1_value}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Home 1 Purchase Price</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{home_1_purchase_price}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Monthly PITI</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{monthly_piti}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Home 1 Mortgage Balance</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{home_1_mortgage_balance}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Max Available Home Equity (80% CLTV)</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{(forms?.calculated_offer?.max_available_home_equity_80_percent_cltv < 0)  ? "$" + "" + "(" + Math.abs(forms?.calculated_offer?.max_available_home_equity_80_percent_cltv)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ")" : max_available_home_equity_80_percent_cltv}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell3} style={{ lineHeight: '1.35' }}>Property Financial Analysis</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}></TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>New House Maximum Purchase Price</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{new_house_maximum_purchase_price}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Est. Down Payment Expense</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{estimated_downpayment_expense}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Est. PITI Expense</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{estimated_piti_expenses}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Est. Paid Closing Cost</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{estimated_closing_costs}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Est. Commissions</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{estimated_commissions}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Est. Calque Fees ({forms?.calculated_offer?.ppg_percent}% of PPG)</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{estimated_calque_fees}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Equity Required</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{equity_required}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Max HE - Equity Required</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{(forms?.calculated_offer?.max_he_minus_equity_required < 0)  ? "$" + "" + "(" + Math.abs(forms?.calculated_offer?.max_he_minus_equity_required)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ")" : "$" + max_he_minus_equity_required}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell3} style={{ lineHeight: '1.35' }}>PPG Information</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}></TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>PPG Amount</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{homeowner_ppg}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>PPG Percentage</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{ppg_percentage}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Offer Validity Period in Days</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{valid_offer_period}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Marketing Period</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{term_days}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Calque % Fee</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{calque_fee}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Administrative Fee</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}> {administrative_fee}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Total Calque Fees</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{total_calque_fees}</TableCell>
                    </TableRow>
                    {  (extendedValidOfferResult !== null || (forms?.calculated_offer.extended_valid_offer_days && forms?.calculated_offer.new_expiry_date && forms?.calculated_offer.valid_offer_extended_date)) ?
                    <>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell3} style={{ lineHeight: '1.35', fontWeight:"600" }}>Extended Valid Offer Details</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}></TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Extended Valid Offer Days</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{!props.isCalculated ? forms?.calculated_offer.extended_valid_offer_days === null ? '' : forms?.calculated_offer.extended_valid_offer_days : extendedValidOfferResult?.calculated_offer?.extended_valid_offer_days === null ? '' : forms?.calculated_offer.extended_valid_offer_days}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>New Expiry Date</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{!props.isCalculated ? forms?.calculated_offer.new_expiry_date === null ? '' : newExpiryFormattedDate : extendedValidOfferResult?.calculated_offer?.new_expiry_date === null ? '' : newExpiryFormattedDate}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Valid Offer Extended Date</TableCell>
                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{!props.isCalculated ? forms?.calculated_offer.valid_offer_extended_date === null ? '' : validOfferFormattedDate : extendedValidOfferResult?.calculated_offer?.valid_offer_extended_date === null ? '' : validOfferFormattedDate}</TableCell>
                    </TableRow>
                    </>
                    : null}
                </TableBody>
            )
        }
    }
    const templateData = () => {
        let tokenStr = localStorage.getItem('access_token')
        fetch(`${baseUrl.baseUrl}get-homeowner-ppg-templates/${forms?.trade_in_application_id}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${tokenStr}`

                },
            })
            .then(async response => {
                const results = await response.json()
                if (response.status === 200) {
                    setTemplateDataRes(results.result);
                    let temp = results && results.result.find(a => a.template_id === forms?.offer_list[0]?.template_id)
                    if (temp)
                        setTemplateId(temp?.template_id)
                    else
                        setTemplateId(results?.result[0]?.template_id)
                } else if (response.status === 403) {
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const handleTemplateChange = (e) => {
        setTemplateId(e.target.value)
    }
    useEffect(() => {
        templateDataRes && templateDataRes.map((temp_data, index) => {
            if (templateId === temp_data.template_id) {
                setOpenOfferSelectTitle(temp_data.template_name)
            }
        })
    }, [templateId])

    const handleOfferTemplateHeader = (openOfferSelectTitle) => {
        return (
            <div className='d-flex'>
                <div>
                    Homeowner PPG Notification
                </div>
                <div className='offer-template-name'>
                </div>
                <div class="views-widget1">
                    <Select
                        name='offer_template'
                        className="form_select form-select-mgp"
                        id="offer_template"
                        MenuProps={MenuProps}
                        defaultValue={templateId === null ? templateDataRes && templateDataRes[0].template_id : templateId}
                        onChange={(e) => handleTemplateChange(e)}
                        value={templateId === null ? templateDataRes && templateDataRes[0].template_id : templateId}
                        style={{ fontWeight: 'bold' }}
                        title={openOfferSelectTitle}

                    >
                        {
                            templateDataRes && templateDataRes.map((temp_data) => {
                                return (
                                    <StyledMenuItem key={temp_data.template_id} value={temp_data.template_id} id="template_id_options" style={{ width: "245px", overflow: "visible", whiteSpace: "initial", fontWeight: '500' }} >
                                        {temp_data.template_name}
                                    </StyledMenuItem >
                                )
                            })
                        }
                    </Select>
                </div>
            </div>
        )
    }

    useEffect(() => {
        var tokenStr = localStorage.getItem('access_token')
        fetch(`${baseUrl.baseUrl}get-app-status-list/`,
            {
                headers: { "Authorization": `Bearer ${tokenStr}` }
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setStatusoffer(result)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            }
            );
    }, [])
    useEffect(() => {
        setHistoryDatas(forms.offer_list)
    }, [forms]);
    useEffect(() => {
        var data = localStorage.getItem('user_info')
        if (data != null) {
            var tempData = JSON.parse(data)
            if (tempData.groups && tempData.groups.length === 1) {
                if (tempData.groups[0].name === 'Data Entry') {
                    setOfferBtn(true)
                }
            }
        }
    }, [])
    useEffect(() => {
        if (forms.is_envelope_exists === true) {
            setDocusignEnvelopeStatus(true)
        } else {
            setDocusignEnvelopeStatus(false)
        }
    }, [forms.is_envelope_exists])

    function SendOffer(forms, openSnackbar, setOpenSnackbar, templateId, templateDataRes) {
        const handleClose2 = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            setOpenSnackbar(false);
        };
        const initialValues = {
            approved: false
        }

        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        const [detail, setDetail] = React.useState('');
        const [severity_type, setSeverityType] = React.useState('');
        const [sendOfferLoader, setSendOfferLoader] = React.useState(false);
        const [responseAppConfigCalendly, setResponseAppConfigCalendly] = React.useState(null);
        const [offerValidateMsg, setOfferValidateMsg] = React.useState(false)

        const vertical = 'top';
        const horizontal = 'center';
        const handleClickOpen1 = () => {
            setOffer(!offer);
        };
        const [open, setOpen] = React.useState(true);

        const handleClickOpen = () => {
            setOpen(true);
        };
        const onSubmit = (values) => {
            setOfferValidateMsg(true)
            if (values.approved) {
                setLoader(true)
                setSendOfferLoader(true)
                var tokenStr = localStorage.getItem('access_token')
                fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/send-offer-email?template_id=${templateId === null ? templateDataRes && templateDataRes[0].template_id : templateId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${tokenStr}`,
                    },
                })
                    .then(async response => {
                        handleClickOpen();
                        const results = await response.json()
                        if (response.status === 200) {
                            setForms(results.trade_in_application)
                            setLoader(false)
                            setDetail(results.message);
                            setSeverityType("success");
                            setNewStatus(16);
                            setOpenSnackbar(true);
                            await sleep(2000);
                            if (openSnackbar !== true) {
                                setOffer(!offer);
                            }
                            setTimeout(() => {
                                setSendOfferLoader(false)
                            }, 2000)
                        }
                        else {
                            setDetail(results.detail);
                            setSeverityType("error");
                            setLoader(false)
                            setOpenSnackbar(true);
                            setTimeout(() => {
                                setSendOfferLoader(false)
                                setOffer(!offer);
                            }, 2000)
                        }
                        if (response.status === 403) {
                            localStorage.setItem('access_token', '')
                            props.history.push('/');
                        }
                    })
                    .catch((error) => {
                        console.error('error message', error)
                    })
            }
        }
        const handleChange = (event) => {
            setState({ ...state, [event.target.name]: event.target.checked });
        };
        const validate = values => {
            let errors = {}
            if (!values.approved || values.approved === null) {
                errors.generic = 'Please approve if you agree with the details to be sent.';
            }
            return errors;
        }
        const formik = useFormik({
            initialValues,
            validate,
            onSubmit
        })
        const [approvedState, setState] = React.useState({
            checkedB: formik.values.approved
        });
        const moment = require('moment');
        const today = moment();
        const todayTerm = moment();
        const nextWeek = today.add(forms?.calculated_offer?.valid_offer_period, 'days');
        const termDays = todayTerm.add(forms?.trade_in_loan?.term, 'days').format("MM/DD/YYYY")
        useEffect(() => {
            var tokenStr = localStorage.getItem('access_token')
            fetch(`${baseUrl.baseUrlCp}get_application_config/`,
                {
                    headers: {
                        "Authorization": `Bearer ${tokenStr}`
                    }
                })
                .then(async response => {
                    const result = await response.json();
                    if (response.status === 201 || response.status === 200) {
                        result && result.map((res) => {
                            if (res.application_config_key === 'calendly_url_for_offer_email') {
                                setResponseAppConfigCalendly(res.application_config_value)
                            }
                        })
                    }
                    else if (response.status === 403) {
                    }
                }
                )
                .catch((error) => {
                    console.error('error message', error)
                })
            setOfferValidateMsg(false)
        }, [])
        useEffect(() => {
            if (offer === false) {
                setState({
                    checkedB: false
                })
                formik.errors.generic = '';
            }
        }, [offer])

        const handleTempContent = () => {
            let data = templateDataRes && templateDataRes.find(temp => temp.template_id === templateId);
            if (data) {
                return (
                    <div dangerouslySetInnerHTML={{ __html: data.template_content }} />
                )
            } else {
                return (
                    <div dangerouslySetInnerHTML={{ __html: templateDataRes && templateDataRes[0].template_content }} />
                )
            }
        }

        return (
            <>
                <form onSubmit={formik.handleSubmit}>
                    {
                        templateDataRes?.detail ?
                            <div className='d-flex align-items-center justify-content-center' style={{ height: '300px' }}>
                                <p className='font-size: 35px'>{templateDataRes.detail}</p>
                            </div>
                            :
                            <>
                                <div>
                                    {
                                        handleTempContent()
                                    }
                                </div>
                                <Typography>
                                    <Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={formik.handleChange}
                                                    name="approved"
                                                    color="#067de2 !important"
                                                    className="check"
                                                />
                                            }
                                            checked={formik.values.approved}
                                            label={`I approve the above offer to be sent to ${forms.borrowers['0']?.first_name} ${forms.borrowers['0']?.last_name} at ${forms?.borrowers[0]?.email}`}
                                            onBlur={formik.handleBlur}
                                        />
                                        <div className="create">
                                        {formik.touched.approved && formik.errors.generic ? <div className='error'>{formik.errors.generic}</div>:""}
                                        </div>
                                    </Grid>
                                </Typography>
                                <Grid container spacing={2} className="form_layout_btn" style={{ justifyContent: 'center' }}>
                                    <Button className="primary_btn" type="submit" variant="contained" color="primary" disabled={sendOfferLoader === true}>{sendOfferLoader === false ? "Yes" : <CircularProgress size={18} color="white" className="circularProgess" />}</Button>
                                    <Button className="primary_btn_border" variant="outlined" color="primary" onClick={handleClickOpen1} style={{ background: '#fff' }}>
                                        <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-create">
                                            No
                                        </NavLink>
                                    </Button>
                                </Grid>
                            </>
                    }
                </form>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleClose2}
                    anchorOrigin={{ vertical, horizontal }}
                >
                    <Alert onClose={handleClose2} severity={severity_type}>
                        <div className="error" style={{ color: 'white' }}>
                            <span>{detail}</span>
                        </div>
                    </Alert>
                </Snackbar>
            </>
        );
    }
    function Submitstage(formData, openSnackbar, setOpenSnackbar) {
        const [open, setOpen] = React.useState(true);
        const [submitStageLoader, setSubmitStageLoader] = React.useState(false)
        const handleClickOpen = () => {
            setOpen(true);
        };
        const [detail, setDetail] = React.useState('');
        const [severity_type, setSeverityType] = React.useState('');
        const vertical = 'top';
        const horizontal = 'center';
        const handleClose1 = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            setOpenSnackbar(false);
        };
        const initialValues = {
            application_status: null,
            comment: '',
        }

        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }

        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        const updateForm = (id) => {
            let tokenStr = localStorage.getItem('access_token')
            let headers = { "Authorization": `Bearer ${tokenStr}` }
            fetch(`${baseUrl.baseUrl}trade-in-application/${id}/`,
                {
                    headers
                })
                .then(async response => {
                    const result = await response.json()
                    if (response.status === 201 || response.status === 200) {
                        setForms(result)
                    } else if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                    }
                })
        }

        const onSubmit = values => {
            var tokenStr = localStorage.getItem('access_token')
            setSubmitStageLoader(true)
            fetch(`${baseUrl.baseUrl}trade-in-application/${forms?.trade_in_application_id}/update-application-status/`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
                    "Authorization": `Bearer ${tokenStr}`
                },
                body: JSON.stringify({ application_status: values.application_status, comment: values.comment })
            })
                .then(async response => {
                    handleClickOpen();
                    const results = await response.json()
                    if (response.status === 200) {
                        updateForm(forms?.trade_in_application_id)
                        setDetail(results.message);
                        setSeverityType("success");
                        setOpenSnackbar(true);
                        await sleep(2000);
                        setOpenSubmit(false);
                        setSubmitStageLoader(false)
                        props.history.push('/application-create')
                    }
                    else {
                        setDetail(results.detail)
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(2000);
                        setSubmitStageLoader(false)
                        setOpenSubmit(false);
                    }
                    if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                    }
                })
                .catch(console.log)
        }

        const validate = values => {
            let errors = {}
            if (!values.application_status) {
                errors.generic = 'Please fill the mandatory(*) fields';
            }
            return errors;
        }
        const formik = useFormik({
            initialValues,
            onSubmit,
            validate
        })
        useEffect(() => {
            if (formData?.application_status) {
                formik.setFieldValue('application_status', formData.application_status.application_status_id)
            }
        }, [])
        return (
            <>
                <form autoComplete="off" className="color" onSubmit={formik.handleSubmit}>
                    <Typography variant="h6" gutterBottom className="fontBoldsubmit">
                        <Grid item xs={12} sm={12} md={12} lg={12} className="row formDiv">
                            <label><b>Status</b><span className="mark">*</span></label>
                            <div className="views-widget1">
                                <Select
                                    value={formik.values.application_status}
                                    id="application_status_offer"
                                    name='application_status'
                                    onChange={formik.handleChange}
                                    MenuProps={MenuProps}
                                    onBlur={formik.handleBlur}
                                >
                                    {statusoffer.map(item => (
                                        <StyledMenuItem key={item.application_status_id} value={item.application_status_id} style={{ width: "245px", overflow: "visible", whiteSpace: "initial" }} >
                                            {item.application_status}
                                        </StyledMenuItem >
                                    ))}
                                </Select>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="row formDiv">
                            <label><b>Comment</b></label>
                            <TextareaAutosize
                                placeholder="Comments..."
                                style={{ width: "100%", borderRadius: "6px" }}
                                className="form_select comment-area"
                                name='comment'
                                type="text"
                                rowsMin={4}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.comment}
                            />
                        </Grid>
                        <div className="create" style={{ float: 'right' }}>
                            {(formik.touched.application_status)
                                && formik.errors.generic ? <div className="error">{formik.errors.generic}</div> : null}
                        </div>
                    </Typography>

                    <Grid container spacing={2} className="form_layout_btn" style={{ justifyContent: 'center' }}>
                        <Button
                            className="primary_btn"
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={submitStageLoader === true}
                        >
                            {submitStageLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                        </Button>
                        <Button className="primary_btn_border" variant="outlined" color="primary" onClick={handleClickOpen2} style={{ background: '#fff' }}>
                            <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-create">
                                Cancel
                            </NavLink>
                        </Button>
                    </Grid>
                </form>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleClose1}
                    anchorOrigin={{ vertical, horizontal }}
                >
                    <Alert onClose={handleClose1} severity={severity_type}>
                        <div className="error" style={{ color: 'white' }}>
                            <span>{detail}</span>
                        </div>
                    </Alert>
                </Snackbar>
            </>
        );
    }
    // converting date format from yyyy-mm-dd to mm-dd-yyyy
    let newExpiryHistoryStringDate = String(historyDatasv?.new_expiry_date);
    let validOfferHistoryStringDate = String(historyDatasv?.valid_offer_extended_date);
    const resultHistoryFormattedDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${month}-${day}-${year}`;
    };
    let newExpiryHistoryFormattedDate = resultHistoryFormattedDate(newExpiryHistoryStringDate);
    let validOfferHistoryFormattedDate = resultHistoryFormattedDate(validOfferHistoryStringDate);
    function Offerhistorystage() {
        return (
            <>
                <div className="tab-section">
                    <section>
                        <Grid container>
                            <h3 className="section-title">Offer Summary</h3>
                            <Table className={classes.table}>
                                <TableBody style={{ height: '500px' }}>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell3} style={{ lineHeight: '1.35', fontWeight:"600" }}>Existing Home Details</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}></TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Home 1 Value</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.estimated_home_value === null ? '' : "$" + "" + historyDatasv.estimated_home_value?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Home 1 Purchase Price</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.home_1_purchase_price === null ? '' : "$" + "" + historyDatasv.home_1_purchase_price?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Monthly PITI</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.monthly_piti === null ? '' : "$" + "" + historyDatasv.monthly_piti?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Home 1 Mortgage Balance</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.mortgage_payoff_amount === null ? '' : "$" + "" + historyDatasv.mortgage_payoff_amount?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Max Available Home Equity (80% CLTV)</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.max_available_home_equity_80_percent_cltv=== null ? '' : (historyDatasv.max_available_home_equity_80_percent_cltv < 0)  ? "$" + "" + "(" + Math.abs(historyDatasv.max_available_home_equity_80_percent_cltv)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ")" : "$" + "" + historyDatasv.max_available_home_equity_80_percent_cltv?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell3} style={{ lineHeight: '1.35' }}>Property Financial Analysis</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}></TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>New House Maximum Purchase Price</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.new_property_max_purchase_cost === null ? '' : "$" + "" + historyDatasv.new_property_max_purchase_cost?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Est. Down Payment Expense</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.estimated_downpayment_expense === null ? '' : "$" + "" + historyDatasv.estimated_downpayment_expense?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Est. PITI Expense</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.estimated_piti_expenses === null ? '' : "$" + "" + historyDatasv.estimated_piti_expenses?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Est. Paid Closing Cost</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.estimated_closing_costs === null ? '' : "$" + "" + historyDatasv.estimated_closing_costs?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Est. Commissions</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.estimated_commissions === null ? '' : "$" + "" + historyDatasv.estimated_commissions?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Est. Calque Fees ({forms?.calculated_offer?.ppg_percent}% of PPG)</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.estimated_calque_fees === null ? '' : "$" + "" + historyDatasv.estimated_calque_fees?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Equity Required</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.equity_required === null ? '' : "$" + "" + historyDatasv.equity_required?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Max HE - Equity Required</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.max_he_minus_equity_required === null ? '' : (historyDatasv.max_he_minus_equity_required < 0)  ? "$" + "" + "(" + Math.abs(historyDatasv.max_he_minus_equity_required)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ")" : "$" + "" + historyDatasv.max_he_minus_equity_required?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell3} style={{ lineHeight: '1.35' }}>PPG Information</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}></TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>PPG Amount</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.homeowner_ppg === null ? '' : "$" + "" + historyDatasv.homeowner_ppg?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>PPG Percentage</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.guarantee_percentage === null ? '' : historyDatasv.guarantee_percentage?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Offer Validity Period in Days</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.valid_offer_period === null ? '' : historyDatasv.valid_offer_period}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Marketing Period</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.term_days === null ? '' :  historyDatasv.term_days}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Calque % Fee</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.calque_fee === null ? '' : "$" + "" + historyDatasv.calque_fee?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Administrative Fee</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.administrative_fee === null ? '' : "$" + "" + historyDatasv.administrative_fee?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Total Calque Fees</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv.total_calque_fees === null ? '' : "$" + "" + historyDatasv.total_calque_fees?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                    </TableRow>
                                    { (historyDatasv?.extended_valid_offer_days && historyDatasv?.new_expiry_date && historyDatasv?.valid_offer_extended_date) ?
                                    <>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell3} style={{ lineHeight: '1.35', fontWeight:"600" }}>Extended Valid Offer Details</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}></TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Extended Valid Offer Days</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv?.extended_valid_offer_days === null ? '' : historyDatasv?.extended_valid_offer_days}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>New Expiry Date</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv?.new_expiry_date === null ? '' : newExpiryHistoryFormattedDate}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tablecell1} style={{ lineHeight: '1.35' }}>Valid Offer Extended Date</TableCell>
                                        <TableCell className={classes.tablecell2} style={{ lineHeight: '1.35' }}>{historyDatasv?.valid_offer_extended_date === null ? '' : validOfferHistoryFormattedDate}</TableCell>
                                    </TableRow>
                                    </>
                                    : null}
                                </TableBody>
                            </Table>
                        </Grid>
                    </section>
                </div>

            </>
        );
    }
    function CreateDocusignEnvelope(forms, openSnackbar, setOpenSnackbar) {
        const handleClose2 = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            setOpenSnackbar(false);
        };
        const initialValues = {
            propertyBuyerName: null,
            propertyReviewerName: null,
            propertyTemplate:null
        }

        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        const [detail, setDetail] = React.useState('');
        const [severity_type, setSeverityType] = React.useState('');
        const [responseAppConfigCalendly, setResponseAppConfigCalendly] = React.useState(null);
        const [loaderDocusignEnvelope, setLoaderDocusignEnvelope] = useState(false)
        const [reviewerRes, setReviewerRes] = React.useState(null);
        const [buyerRes, setBuyerRes] = React.useState(null)
        const [templateOptions, setTemplateOptions] = React.useState(null)
        const [docusignValidateMsg, setDocusignValidateMsg] = React.useState(false)

        const vertical = 'top';
        const horizontal = 'center';
        const handleClickOpen1 = () => {
            setDocusignEnvelope(!docusignEnvelope);
        };
        const [open, setOpen] = React.useState(true);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const onSubmit = (values) => {
            setDocusignValidateMsg(true)
            if(formik.values.propertyTemplate && formik.values.propertyBuyerName && formik.values.propertyReviewerName) {
            setLoaderDocusignEnvelope(true)
            var tokenStr = localStorage.getItem('access_token')
            fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/create-docusign-envelope/?docusign_template_id=${formik.values.propertyTemplate}&buyer_id=${formik.values.propertyBuyerName}&reviewer_id=${formik.values.propertyReviewerName}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${tokenStr}`,
                },
            })
                .then(async response => {
                    handleClickOpen();
                    const results = await response.json()
                    if (response.status === 200) {
                        setLoaderDocusignEnvelope(false);
                        setDocusignEnvelopeStatus(true);
                        if (results?.trade_in_application !== undefined) {
                            setForms(results.trade_in_application)
                        }
                        setDetail(results.message);
                        setSeverityType("success");
                        setOpenSnackbar(true);
                        await sleep(2000);
                        if (openSnackbar !== true) {
                            setDocusignEnvelope(!docusignEnvelope);
                        }
                        setTimeout(() => {
                            setLoaderDocusignEnvelope(false)
                        }, 2000)
                    }
                    else {
                        setDetail(results.message);
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(3000);
                        setLoaderDocusignEnvelope(false)
                        setDocusignEnvelopeStatus(false);
                        setTimeout(() => {
                            setLoaderDocusignEnvelope(false)
                            setDocusignEnvelope(!docusignEnvelope);
                        }, 2000)
                    }
                    if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                    }
                })
                .catch((error) => {
                    console.error('error message', error)
                    setDocusignEnvelopeStatus(false);
                })
            } else {
                setDocusignValidateMsg(true)
            }
        }
        const validate = values => {
            let errors = {}
            if((!values.propertyTemplate || values.propertyTemplate === null) && (!values.propertyBuyerName || values.propertyBuyerName === null) && (!values.propertyReviewerName || values.propertyReviewerName === null)){
                errors.generic = 'Please select the above all fields.'
            }else if((!values.propertyTemplate || values.propertyTemplate === null) && (!values.propertyBuyerName || values.propertyBuyerName === null) && (!values.propertyReviewerName || values.propertyReviewerName === null)){
                errors.generic = "Please select the above all fields."
            }else if( (!values.propertyTemplate || values.propertyTemplate === null) && (!values.propertyBuyerName || values.propertyBuyerName === null)){
                errors.generic = "Please select the above all fields."
            }else if( (!values.propertyTemplate || values.propertyTemplate === null) && (!values.propertyReviewerName || values.propertyReviewerName === null)){
                errors.generic = "Please select the above all fields."
            }else if( (!values.propertyReviewerName || values.propertyReviewerName === null) && (!values.propertyBuyerName || values.propertyBuyerName === null)){
                errors.generic = "Please select the above all fields."
            }else if (!values.propertyTemplate || values.propertyTemplate === null ){
                errors.generic = 'Please select the Template.'
            }else if (!values.propertyBuyerName || values.propertyBuyerName === null ) {
                errors.generic = 'Please select the buyer name.';
            }else if (!values.propertyReviewerName || values.propertyReviewerName === null ) {
                errors.generic = 'Please select the reviewer name.';
            }
           
            return errors;
        }
        const formik = useFormik({
            initialValues,
            validate,
            onSubmit
        })
        useEffect(() => {
            var tokenStr = localStorage.getItem('access_token')
            fetch(`${baseUrl.baseUrlCp}get_application_config/`,
                {
                    headers: {
                        "Authorization": `Bearer ${tokenStr}`
                    }
                })
                .then(async response => {
                    const result = await response.json();
                    if (response.status === 201 || response.status === 200) {
                        result && result.map((res) => {
                            if (res.application_config_key === 'calendly_url_for_offer_email') {
                                setResponseAppConfigCalendly(res.application_config_value)
                            }
                        })
                    }
                    else if (response.status === 403) {
                    }
                }
                )
                .catch((error) => {
                    console.error('error message', error)
                })
            fetch(`${baseUrl.baseUrl}get-docusign-reviewer-details/`,
                {
                    headers: {
                        "Authorization": `Bearer ${tokenStr}`
                    }
                })
                .then(async response => {
                    const result = await response.json();
                    if (response.status === 201 || response.status === 200) {
                        setReviewerRes(result)
                        formik.setFieldValue('propertyReviewerName', forms?.offer_list?.[0]?.reviewer_id)
                    }
                    else if (response.status === 403) {
                    }
                }
                )
                .catch((error) => {
                    console.error('error message', error)
                })
            fetch(`${baseUrl.baseUrl}get-docusign-buyer-details/`,
                {
                    headers: {
                        "Authorization": `Bearer ${tokenStr}`
                    }
                })
                .then(async response => {
                    const result = await response.json();
                    if (response.status === 201 || response.status === 200) {
                        setBuyerRes(result)
                        formik.setFieldValue('propertyBuyerName', forms?.offer_list?.[0]?.buyer_id)
                    }
                    else if (response.status === 403) {
                    }
                }
                )
                .catch((error) => {
                    console.error('error message', error)
                })
            fetch(`${baseUrl.baseUrl}get-docusign-templates/`,
                {
                    headers: {
                        "Authorization": `Bearer ${tokenStr}`
                    }
                })
                .then(async response => {
                    const result = await response.json();
                    if (response.status === 201 || response.status === 200) {
                        setTemplateOptions(result)
                        formik.setFieldValue('propertyTemplate', forms?.offer_list?.[0]?.docusign_template_id)
                    }
                    else if (response.status === 403) {
                    }
                }
                )
                .catch((error) => {
                    console.error('error message', error)
                })
            setDocusignValidateMsg(false)
        }, [])
        return (
            <div>
                <form onSubmit={formik.handleSubmit}>
                    {
                        <>
                        <Grid container>
                        <div class="views-widget-offer" style={{ marginTop: '20px', marginBottom: '10px' }}>
                        <select
                            name='propertyTemplate'
                            className="send_offer_select"
                            style={{ color: '#000' }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.propertyTemplate}
                        >
                            <option value="">&nbsp;---Select Docusign Template---</option>
                            {
                                templateOptions && templateOptions.map((templates, index) => {
                                    return (
                                        <option key={index} value={templates.docusign_templates_id}>&nbsp;{templates.template_name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div class="views-widget-offer" style={{ marginTop: '20px', marginBottom: '10px' , marginLeft:"35px"}}>
                        <select
                            name='propertyReviewerName'
                            className="send_offer_select"
                            style={{ color: '#000' }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.propertyReviewerName}
                        >
                            <option value="">&nbsp;---Select Reviewer Name---</option>
                            {
                                reviewerRes && reviewerRes.map((reviewer, index) => {
                                    return (
                                        <option key={index} value={reviewer.docusign_reviewer_details_id}>&nbsp;{reviewer.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div class="views-widget-offer" style={{ marginTop: '20px', marginBottom: '10px' , marginLeft:"35px"}}>
                        <select
                            name='propertyBuyerName'
                            className="send_offer_select"
                            style={{ color: '#000' }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.propertyBuyerName}
                        >
                            <option value="">&nbsp;---Select Buyer Name---</option>
                            {
                                buyerRes && buyerRes.map((buyer, index) => {
                                    return (
                                        <option key={index} value={buyer.docusign_buyer_details_id}>&nbsp;{buyer.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    </Grid>
                        </>
                    }
                    <Typography>
                        <Grid>
                            <div className="create">
                                {(docusignValidateMsg === true && !formik.values.propertyTemplate && !formik.values.propertyBuyerName && !formik.values.propertyReviewerName) ? <div className='error'>Please select the above all fields.</div>:formik.touched.propertyTemplate && formik.errors.generic ? <div className='error'>{formik.errors.generic}</div>:formik.touched.propertyReviewerName && formik.errors.generic ? <div className='error'>{formik.errors.generic}</div>:formik.touched.propertyBuyerName && formik.errors.generic ? <div className='error'>{formik.errors.generic}</div>:""}
                            </div>
                        </Grid>
                    </Typography>
                </form>
                <div className="d-flex align-items-center justify-content-center">
                    <div>
                        <Button
                            className="primary_btn"
                            onClick={onSubmit}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={loaderDocusignEnvelope === true}
                        >
                            {loaderDocusignEnvelope === false ? "Save" :
                                <CircularProgress size={18} color="white" className="circularProgess" />}
                        </Button>
                    </div>
                    <div>
                        <Button className="primary_btn_border" variant="outlined" color="primary" onClick={handleClickOpen1} style={{ background: '#fff' }}>
                            <NavLink style={{ textDecoration: 'none', color: '#067DE2', fontSize: '14px' }} exact to="/application-create">
                                Cancel
                            </NavLink>
                        </Button>
                    </div>
                </div>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleClose2}
                    anchorOrigin={{ vertical, horizontal }}
                >
                    <Alert onClose={handleClose2} severity={severity_type}>
                        <div className="error" style={{ color: 'white' }}>
                            <span>{detail}</span>
                        </div>
                    </Alert>
                </Snackbar>
            </div>
        )
    }
    function SendSigningUrl(forms, openSnackbar, setOpenSnackbar) {
        const handleClose2 = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            setOpenSnackbar(false);
        };
        const handleCloseSigningUrl = () => {
            setSignUrl(false)
        }
        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        const [detail, setDetail] = React.useState('');
        const [severity_type, setSeverityType] = React.useState('');
        const vertical = 'top';
        const horizontal = 'center';
        const handleSigningUrl = () => {
            setSignUrl(!signUrl);
        };
        const [open, setOpen] = React.useState(true);

        const handleClickOpen = () => {
            setOpen(true);
        };
        const onSubmit = (values) => {
            setLoaderSigning(true)
            var tokenStr = localStorage.getItem('access_token')
            fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/send-signing-url/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${tokenStr}`,
                },
            })
                .then(async response => {
                    handleClickOpen();
                    const results = await response.json()
                    if (response.status === 200) {
                        if (results?.trade_in_application !== undefined) {
                            setForms(results.trade_in_application)
                        }
                        setDetail(results.message);
                        setNewStatus(12);
                        setSeverityType("success");
                        setOpenSnackbar(true);
                        await sleep(2000);
                        if (openSnackbar !== true) {
                            setSignUrl(!signUrl);
                        }
                        setTimeout(() => {
                            setLoaderSigning(false)
                        }, 2000)
                    }
                    else {
                        setDetail(results.message);
                        setSeverityType("error");
                        setLoader(false)
                        setOpenSnackbar(true);
                        await sleep(3000);
                        handleCloseSigningUrl()
                        setTimeout(() => {
                            setLoaderSigning(false)
                        }, 2000)
                    }
                    if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                    }
                })
                .catch((error) => {
                    console.error('error message', error)
                })
        }
        return (
            <div>
                <h1 style={{ textAlign: 'center' }}>Are you sure you want to send docusign signing URL?</h1>
                <div className="d-flex align-items-center justify-content-center">
                    <div>
                        <Button
                            className="primary_btn"
                            onClick={onSubmit}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={loaderSigning === true}
                        >
                            {loaderSigning === false ? "Yes" :
                                <CircularProgress size={18} color="white" className="circularProgess" />}
                        </Button>
                    </div>
                    <div>
                        <Button className="primary_btn_border" variant="outlined" color="primary" onClick={handleCloseSigningUrl} style={{ background: '#fff' }}>
                            <NavLink style={{ textDecoration: 'none', color: '#067DE2', fontSize: '14px' }} exact to="/application-create">
                                No
                            </NavLink>
                        </Button>
                    </div>
                </div>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleClose2}
                    anchorOrigin={{ vertical, horizontal }}
                >
                    <Alert onClose={handleClose2} severity={severity_type}>
                        <div className="error" style={{ color: 'white' }}>
                            <span>{detail}</span>
                        </div>
                    </Alert>
                </Snackbar>
            </div>
        )
    }
    function ExtendValidOffer(forms, openSnackbar, setOpenSnackbar) {
        const handleClose2 = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            setOpenSnackbar(false);
        };
        const handleValidOfferDays = e => {
            if (e.key === " " || e.key === ".") {
              e.preventDefault();
            }
        };
        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        const [detail, setDetail] = React.useState('');
        const [severity_type, setSeverityType] = React.useState('');

        const vertical = 'top';
        const horizontal = 'center';
        const handleClickOpen1 = () => {
            setValidOffer(!validOffer);
            setExtendedDays('');
            setNewExpiryOrExpiredDate('');
            setExtendDaysErrMsg('')
        };
        const [open, setOpen] = React.useState(true);

        const handleClickOpen = () => {
            setOpen(true);
        };

        // converting date format from yyyy-mm-dd to mm-dd-yyyy
        let stringDate = String(offerExpiryDate);
        const resultFormatDate = (dateString) => {
            const [year, month, day] = dateString.split('-');
            return `${month}-${day}-${year}`;
        };
        const finalFormattedDate = resultFormatDate(stringDate);

        // calculation for new expiry date
        const formatDate = (date) => {
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();
            return `${month}-${day}-${year}`;
        };
        const calculateNewExpiredDate = (days) => {
            if (!days) return '';
            const currentDate = new Date(); 
            const resultDate = new Date(currentDate);
            resultDate.setDate(currentDate.getDate() + Number(days));
            return formatDate(resultDate);
        };
        const calculateNewExpiryDate = (days) => {
            if (!days) return '';
            const currentDate = new Date(offerExpiryDate); 
            const resultDate = new Date(currentDate);
            resultDate.setDate(resultDate.getDate() + Number(days));
            return formatDate(resultDate);
        };
        const handleDaysChange = (e) => {
            if (extendedDays?.trim() !== '' || e.target.value !== '') {
                setExtendDaysErrMsg('');
            }
            const { value } = e.target;
            setExtendedDays(value)
            if(isExpiredDate === true) {
                const newExpiredDate = calculateNewExpiredDate(value);
                setNewExpiryOrExpiredDate(newExpiredDate);
            } else {
                const newExpiryDate = calculateNewExpiryDate(value);
                setNewExpiryOrExpiredDate(newExpiryDate);
            }
        };

        // to send date to API 
        const resultNewExpiryFormatDate = (dateString) => {
            const [month, day, year] = dateString.split('-');
            return `${year}-${month}-${day}`;
        };
    
        const onSubmit = () => {
            let isValid = true;
            if (extendedDays === null || extendedDays === '' || Number(extendedDays) == 0) {
                setExtendDaysErrMsg('Please enter the number of days');
                isValid = false;
            } else {
                setExtendDaysErrMsg('');
            }
            const finalFormattedNewExpiryDate = resultNewExpiryFormatDate(newExpiryOrExpiredDate);
            if(extendedDays !== null && extendedDays !== '' && Number(extendedDays) !== 0) {
            var tokenStr = localStorage.getItem('access_token')
            fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/save-extended-valid-offer-days/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                },
                body: JSON.stringify({ days: Number(extendedDays), new_expiry_date: finalFormattedNewExpiryDate })
            })
                .then(async response => {
                    handleClickOpen();
                    const results = await response.json()
                    if (response.status === 200) {
                        if (results?.trade_in_application !== undefined) {
                            setForms(results.trade_in_application)
                        }
                        setExtendedValidOfferResult(results?.trade_in_application)
                        setDetail(results.message);
                        setSeverityType("success");
                        setOpenSnackbar(true);
                        await sleep(2000);
                        if (openSnackbar !== true) {
                            setValidOffer(!validOffer);
                        }
                        setExtendedDays('')
                        setNewExpiryOrExpiredDate('')
                        setExtendDaysErrMsg('')
                    }
                    else {
                        setExtendedDays('')
                    }
                    if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                    }
                })
                .catch((error) => {
                    console.error('error message', error)
                })
            } 
        }
        
        return (
            <div>
                { isExpiredDate === true ?
                <>
                    <p className='validOfferText'>Existing Offer Has Expired On <b>{finalFormattedDate === undefined-undefined-undefined ? '' : finalFormattedDate}</b></p>
                    <h1 className='validOfferHeading'>How many days from today do you want to extend the expiry date?</h1> 
                </> :
                <>
                    <p className='validOfferText'>Existing Offer Will Expire On <b>{finalFormattedDate === undefined-undefined-undefined ? '' : finalFormattedDate}</b></p>
                    <h1 className='validOfferHeading'>How many days from existing expiry date do you want to extend the existing offer?</h1>
                </>
                } 
                <div className='extend_valid_offer_container'>
                <Grid item xs={12} sm={12} md={12} lg={12} className="formDiv validOfferForm">
                    <label>Enter Number of Days:<span className="red-color">*</span></label>
                    <NumberFormat
                        name='validOfferDays'
                        className="numberField"
                        autoComplete='off'
                        allowNegative={false}
                        inputMode="numeric"
                        placeholder='0'
                        allowEmptyFormatting
                        maxLength={3}
                        value={extendedDays}
                        isAllowed={(values) => {
                            const { floatValue } = values;
                            return (floatValue >= 0 && floatValue <= 100) || !floatValue;
                        }}
                        onKeyDown={handleValidOfferDays}
                        onChange={handleDaysChange} 
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="formDiv validOfferForm" style={{marginRight:'167px'}}>
                    <label>New Expiry Date:</label>
                    <p className='new_expiry_date'>{extendedDays == 0 ? '' : newExpiryOrExpiredDate}</p>
                </Grid>
                </div>
                <Typography>
                    <Grid>
                        <div className="create" style={{textAlign: 'center', height: '12px'}}>
                            {extendDaysErrMsg && <span className="form-label" style={{color: 'red'}}>{extendDaysErrMsg}</span>}
                        </div>
                    </Grid>
                </Typography>
                <div className="d-flex align-items-center justify-content-center">
                    <div>
                        <Button
                            className="primary_btn"
                            onClick={onSubmit}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                    </div>
                    <div>
                        <Button className="primary_btn_border" variant="outlined" color="primary" onClick={handleClickOpen1} style={{ background: '#fff' }}>
                            <NavLink style={{ textDecoration: 'none', color: '#067DE2', fontSize: '14px' }} exact to="/application-create">
                                Cancel
                            </NavLink>
                        </Button>
                    </div>
                </div>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleClose2}
                    anchorOrigin={{ vertical, horizontal }}
                >
                    <Alert onClose={handleClose2} severity={severity_type}>
                        <div className="error" style={{ color: 'white' }}>
                            <span>{detail}</span>
                        </div>
                    </Alert>
                </Snackbar>
            </div>
        )
    }

    return (
        <>
            <CommonHeader cxSpecialists={props.cxSpecialists} statusOffer={props.statusOffer} cxSpecialist={props.cxSpecialist} setCxSpecialist={props.setCxSpecialist} />
            <div className="tab-section">
                <section>
                    <Grid container className="offers-tab">
                        <Grid container md={12}>
                            <Grid md={6}>
                                <h3 className="section-title">Offer Summary</h3>
                            </Grid>
                            <Grid md={6} spacing={2} className="form_valoff_btn">
                            { isExtendBtn === true ?
                                <Button
                                    className="primary_btn offer_btn"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleValidOffer}
                                >
                                    Extend Valid Offer Days
                                </Button>
                                : null}
                            </Grid>
                        </Grid>
                        <Table className={classes.table}>
                            {offersData()}
                        </Table>
                        {forms?.calculated_offer?.homeowner_ppg > 1200000 && <Grid style={{ justifyContent:"end" }} container>
                            <p style={{ color:"#ff0000",marginBottom:"0px"}}>Offers above $1,200,000 are not allowed</p>
                        </Grid>}
                        <Grid container spacing={2} className="form_layout_btn">
                            {/* <Button
                                className="primary_btn"
                                variant="contained"
                                color="primary"
                                onClick={handleClickOpen2}
                            >
                                Submit Stage
                            </Button> */}
                            {(newStatus === 2 || newStatus === 18 || newStatus === 6 || newStatus === 12 || newStatus === 13 || newStatus === 16) ? offerBtn === true ? null : 
                            <Button
                                className="primary_btn offer_btn"
                                variant="contained"
                                color="primary"
                                disabled={forms?.calculated_offer?.homeowner_ppg > 1200000 ? true : false}
                                onClick={() => { handleClickOpen1(); templateData() }}
                            >
                                Send Offer Email
                            </Button>
                                : null}
                            { newStatus === 19 ? offerBtn === true ? null :
                            <Button
                                className="primary_btn offer_btn"
                                variant="contained"
                                color="primary"
                                disabled={forms?.calculated_offer?.homeowner_ppg > 1200000 ? true : false}
                                onClick={() => { handleClickOpen1(); templateData() }}
                            >
                                Send Offer Email
                            </Button>
                             : null}
                            {
                                (isEnvelopeBtn === true) ?
                                    <Button
                                        className="docusign_btn"
                                        variant="contained"
                                        color="primary"
                                        disabled={forms?.calculated_offer?.homeowner_ppg > 1200000 ? true : false}
                                        onClick={handleDocusignEnvelope}
                                    >
                                        Create DocuSign Envelope
                                    </Button>
                                    : null
                            }
                            {
                                (docusignEnvelopeStatus === true && (newStatus === 16 || newStatus === 26 || newStatus === 12 || newStatus === 13)) ?
                                    <Button
                                        className="primary_btn"
                                        variant="contained"
                                        color="primary"
                                        //disabled={forms?.calculated_offer?.homeowner_ppg > 1200000 ? true : false}
                                        onClick={handleSigningUrl}
                                    >
                                        Send DocuSign Signing URL
                                    </Button>
                                    : null
                            } 
                            {
                                (docusignEnvelopeStatus === true && newStatus === '5') ?
                                    <Button
                                        className="primary_btn"
                                        variant="contained"
                                        color="primary"
                                        disabled={forms?.calculated_offer?.homeowner_ppg > 1200000 ? true : false}
                                        onClick={handleSigningUrl}
                                    >
                                        Send DocuSign Signing URL
                                    </Button>
                                    : null
                            } 
                            {/* {applicationId && isTestApp === false ?
                                        <Button
                                            className="primary_btn"
                                            variant="contained"
                                            style={{width:"180px"}}
                                            color="primary"
                                            onClick={(e) => { handleNutshell(e) }}
                                            disabled={nuthshellAPILoader === true}
                                        >
                                            {nuthshellAPILoader === false ? 'Sync With Nutshell' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                                    </Button>
                                    : null
                            } */}
                            <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-listing">
                                <Button
                                    className="primary_btn_border"
                                    variant="outlined"
                                    color="primary"
                                    style={{ background: '#fff' }}>
                                    Cancel
                                </Button>
                            </NavLink>
                        </Grid>
                    </Grid>
                </section>

                <Grid container className="offers-tab">
                    <h3 className="section-title">Offer History</h3>
                    <TableContainer>
                        <Table aria-label="simple table" className={`${classes.tableview} table`} >
                            <TableHead className="head">
                                <TableRow style={{ width: '100%' }}>
                                    <TableCell align="right" className="fontBold" style={{ width: '12%' }}>Sent By</TableCell>
                                    <TableCell align="right" className="fontBold" style={{ width: '12%' }}>Reviewer Name</TableCell>
                                    <TableCell align="right" className="fontBold" style={{ width: '12%' }}>Buyer Name</TableCell>
                                    <TableCell align="right" className="fontBold" style={{ width: '20%' }}>Docusign Template</TableCell>
                                    <TableCell align="right" className="fontBold" style={{ width: '15%' }}>Template Name</TableCell>
                                    <TableCell align="right" className="fontBold" style={{ width: '17%' }}>Sent Date</TableCell>
                                    <TableCell align="right" className="fontBold" style={{ width: '20%' }}>View</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table_border_body">
                                {historyDatas.map((row, index) => {
                                    return (<TableRow key={index}>
                                        <TableCell component="th" scope="row" >
                                            {row?.created_by}
                                        </TableCell>
                                        <TableCell align="right">{row?.reviewer_name == undefined ? '-' : row?.reviewer_name}</TableCell>
                                        <TableCell align="right">{row?.buyer_name == undefined ? '-' : row?.buyer_name}</TableCell>
                                        <TableCell align="right">{row?.docusign_template == undefined ? '-' : row?.docusign_template}</TableCell>
                                        <TableCell align="right">{row?.template_name == undefined ? '-' : row?.template_name}</TableCell>
                                        <TableCell align="right">{row?.created_date ? new Date(row.created_date).toLocaleString('en-US', 'options', { hour12: false }) : 'NA'}</TableCell>
                                        <TableCell align="right"
                                            onClick={function (event) {
                                                getHistory(row, forms);
                                                setTimeout(() => {
                                                    setOfferHistory(!offerHistory);
                                                }, 500)
                                            }
                                            }
                                        >
                                            <td style={{ marginLeft: 10 }} onClick={handleClickOpen3}><b className="primary-text d-flex align-center"><Visibility /> View</b></td>
                                        </TableCell>
                                    </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </div>
            <Snackbar open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleClose1}
                anchorOrigin={{ vertical, horizontal }} >
                <Alert onClose={handleClose1} severity={severity_type}>
                    <div className="error" style={{ color: 'white' }}>
                        <span>{detail}</span>
                    </div>
                </Alert>
            </Snackbar>
            <ModalpopupOffer open={offer} handleClickOpen={handleClickOpen1} deactiveUser={SendOffer(forms, openSnackbarSendOffer, setOpenSnackbarSendOffer, templateId, templateDataRes)} header={handleOfferTemplateHeader(openOfferSelectTitle)} />
            <Modalpopup open={openSubmit} handleClickOpen={handleClickOpen2} deactiveUser={Submitstage(forms, openSnackbarSubmitStage, setOpenSnackbarSubmitStage)} header="Application Status" />
            <DocusignEnvelopeModal open={docusignEnvelope} handleClickOpen={handleDocusignEnvelope} deactiveUser={CreateDocusignEnvelope(forms, openSnackbarDocusignEnvelope, setOpenSnackbarDocusignEnvelope)} header="Select The Following" />
            <Modalpopup open={signUrl} handleClickOpen={handleSigningUrl} deactiveUser={SendSigningUrl(forms, openSnackbarSendSigningUrl, setOpenSnackbarSendSigningUrl)} header="Send DocuSign Signing URL" />
            <Reviewmodal open={offerHistory} class="history" handleClickOpen={handleClickOpen3} deactiveUser={Offerhistorystage()} header="Offer History" />
            <Reviewmodal open={validOffer} modalWidth={"sm"} handleClickOpen={handleValidOffer} deactiveUser={ExtendValidOffer(forms, openSnackbarValidOffer, setOpenSnackbarValidOffer)} header="Extend Valid Offer Days" /> 
        </>
    )
}