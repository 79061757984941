import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import '../../styles/Mgptab.css'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CustomDialog = withStyles((theme)=>({
  root:{
    height:630
  }
}))(Dialog)

export default function Reviewmodal(props) {
  const [open, setOpen] = React.useState(true);
  return (
    <>
      <div>
        <CustomDialog maxWidth={props.modalWidth === "sm" ? "sm" : props.valuationClass ? "lg" : "md"} onClose={props.handleClickOpen} aria-labelledby="customized-dialog-title" open={props.open} className="Modal_Matrix">
          <DialogTitle className="modal_header" onClose={props.handleClickOpen}>
            {props.header}
          </DialogTitle>
          <DialogContent dividers className={props.class}>
            {props.deactiveUser}
          </DialogContent>
        </CustomDialog>
      </div>
    </>
  );
}